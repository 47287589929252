import React, {useState, useEffect} from 'react';
import {Container, Row, Col, Card, Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPencilAlt} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import {collection, doc, getDoc, updateDoc} from 'firebase/firestore';
import {getAuth} from 'firebase/auth';
import db from '../../firebase';
import Avatar from '../../components/common/Avatar';

const UserProfile = () => {
    const [userData, setUserData] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editedData, setEditedData] = useState({});
    const [avatarFile, setAvatarFile] = useState(null);
    const [inputValues, setInputValues] = useState({});
    const {currentUser, connectToGoogleAnalytics} = getAuth();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                if (currentUser) {
                    const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
                    if (userDoc.exists()) {
                        setUserData(userDoc.data());
                    }
                }
            } catch (error) {
                console.log('Erro ao buscar informações do usuário:', error);
            }
        };

        fetchUserData();
    }, [currentUser]);

    const handleEditClick = () => {
        setIsEditing(true);
        setEditedData(userData);
    };

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setEditedData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSaveClick = async () => {
        try {
            await updateDoc(doc(db, 'users', currentUser.uid), editedData);
            setIsEditing(false);
            setUserData(editedData);
        } catch (error) {
            console.log('Erro ao salvar informações do usuário:', error);
        }
    };

    const handleAvatarClick = async () => {
        try {
            const selectedFile = await selectImage();
            const croppedImage = await cropImage(selectedFile);
            const resizedImage = await resizeImage(croppedImage, 500)
            const downloadURL = await uploadImage(resizedImage);
            await updateAvatarInFirestore(downloadURL);
            const updatedUserData = {...userData, avatar: downloadURL};
            setUserData(updatedUserData);
        } catch (error) {
            console.error('Ocorreu um erro ao atualizar o avatar:', error);
        }
    };

    const renderUserInfo = () => {
        if (!userData) {
            return <p>Carregando informações do usuário...</p>;
        }

        return (
            <>
                <Row>
                    <Col sm={3}>
                        <p className="mb-0">Nome</p>
                    </Col>
                    <Col sm={7}>
                        {isEditing ? (
                            <input
                                type="text"
                                className="form-control"
                                name="name"
                                value={inputValues.name || editedData.name}
                                onChange={handleInputChange}
                            />
                        ) : (
                            <p className="text-muted mb-0">{userData.name}</p>
                        )}
                    </Col>
                    <Col sm={2} className="text-end">
                        {!isEditing && (
                            <Button
                                variant="primary"
                                className="text-white py-0 px-3"
                                title="Editar Informações"
                                onClick={handleEditClick}
                            >
                                <FontAwesomeIcon icon={faPencilAlt}/>
                            </Button>
                        )}
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col sm={3}>
                        <p className="mb-0">E-mail</p>
                    </Col>
                    <Col sm={9}>
                        <p className="text-muted mb-0">{userData.email}</p>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col sm={3}>
                        <p className="mb-0">Telefone</p>
                    </Col>
                    <Col sm={9}>
                        {isEditing ? (
                            <input
                                type="text"
                                className="form-control"
                                name="phone"
                                value={inputValues.phone || editedData.phone}
                                onChange={handleInputChange}
                            />
                        ) : (
                            <p className="text-muted mb-0">{userData.phone}</p>
                        )}
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col sm={3}>
                        <p className="mb-0">Empresa</p>
                    </Col>
                    <Col sm={9}>
                        {isEditing ? (
                            <input
                                type="text"
                                className="form-control"
                                name="company"
                                value={inputValues.company || editedData.company}
                                onChange={handleInputChange}
                            />
                        ) : (
                            <p className="text-muted mb-0">{userData.company}</p>
                        )}
                    </Col>
                </Row>
                <Row>
                    {isEditing && (
                        <Button
                            variant="primary"
                            className="text-white py-0 px-3"
                            onClick={handleSaveClick}
                        >
                            Salvar
                        </Button>
                    )}
                </Row>
            </>
        );
    };

    return (
        <Container>
            <Row>
                <Col>
                    <nav aria-label="breadcrumb" className="bg-light rounded-3 p-3 mb-4">
                        <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item">
                                <a href="/">Home</a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                Perfil
                            </li>
                        </ol>
                    </nav>
                </Col>
            </Row>
            <Row>
                <Col lg={4}>
                    <Card className="mb-4">
                        <Card.Body className="text-center">
                            <Avatar src={userData?.avatar ?? ''}/>
                            <div className="mt-2">
                                <small>
                                    <a href="#" onClick={handleAvatarClick}>Atualizar avatar</a>
                                </small>
                            </div>
                            <Card.Title className="my-3">{userData?.name}</Card.Title>
                            <Card.Text className="text-muted mb-1">
                                {userData?.user_type}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={8}>
                    <Card>
                        <Card.Body>{renderUserInfo()}</Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

UserProfile.propTypes = {
    avatar: PropTypes.string,
};

export default UserProfile;
