// Importe as dependências corretamente
import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {Col, Row} from 'react-bootstrap';
import ConnectCard from "../../../components/dashboards/analytics/ConnectCard";
import Audience from "../../../components/dashboards/analytics/audience/Audience";
import {
    audienceChart,
    intelligence,
    realTimeUsers,
    sessionByBrowser,
    sessionByCountry
} from "../../../data/dashboard/analytics";
import RealTimeUsers from "../../../components/dashboards/analytics/real-time-users/RealTimeUsers";
import SessionByBrowser from "../../../components/dashboards/analytics/session-by-browser/SessionByBrowser";
import UsersByCountry from "../../../components/dashboards/analytics/users-by-country/UsersByCountry";
import {countryData} from "../../../data/countryData";
import Intelligence from "../../../components/dashboards/analytics/Intelligence";
import Greetings from "../../../components/dashboards/crm/greetings/Greetings";
import CrmStats from "../../../components/dashboards/crm/CrmStats";
import Revenue from "../../../components/dashboards/crm/revenue/Revenue";
import MostLeads from "../../../components/dashboards/crm/most-leads/MostLeads";
import DealForecastBar from "../../../components/dashboards/crm/DealForecastBar";
import DealStorageFunnel from "../../../components/dashboards/crm/deal-storage-funnel/DealStorageFunnel";
import DealVsGoal from "../../../components/dashboards/crm/deal-vs-goal/DealVsGoal";
import DealForeCast from "../../../components/dashboards/crm/deal-forecast/DealForeCast";

const Cockpit = ({selectedProject}) => {
    const {projectId} = useParams(); // Obtém o ID do projeto da URL
    const [projectName, setProjectName] = useState('');

    useEffect(() => {
        // Verifique se há um projeto selecionado e defina o nome do projeto
        if (selectedProject) {
            setProjectName(selectedProject.name);
        }
    }, [selectedProject]);

    return (
        <>
            <Greetings />
            <Row className="g-3 mb-3">
                <Col xxl={9}>
                    <ConnectCard/>
                    <CrmStats />
                    <DealVsGoal />
                </Col>
                <Col xxl={3}>
                    <MostLeads />
                </Col>
                <Col xxl={12}>
                    <DealForeCast />
                </Col>
                <Col md={12} xxl={8}>
                    <DealForecastBar />
                </Col>
                <Col xxl={4}>
                    <DealStorageFunnel />
                </Col>
            </Row>
        </>
    );
};

export default Cockpit;
