import React, {useEffect, useState} from 'react';
import {Row, Col, Form, Button, Card} from 'react-bootstrap';
import Select from 'react-select';
import {collection, getDocs, query, updateDoc, doc} from 'firebase/firestore';
import db from '../../firebase';
import {useParams} from 'react-router-dom';

const ProjectConfig = () => {
    const [projectName, setProjectName] = useState('');
    const [users, setUsers] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [userOptions, setUserOptions] = useState([]);
    const {id} = useParams();

    useEffect(() => {
        async function fetchProjectData() {
            try {
                const projectDoc = doc(db, 'projects', id);
                const projectSnapshot = await getDocs(projectDoc);
                if (projectSnapshot.exists()) {
                    const projectData = projectSnapshot.data();
                    setProjectName(projectData.name);
                    setSelectedOptions(projectData.selectedOptions || []);
                }
            } catch (error) {
                console.log('Erro ao obter dados do projeto:', error);
            }
        }

        fetchProjectData();
    }, [id]);

    useEffect(() => {
        async function fetchUsers() {
            const usersQuery = query(collection(db, 'users'));
            const querySnapshot = await getDocs(usersQuery);
            const usersData = querySnapshot.docs.map((doc) => ({id: doc.id, name: doc.data().name}));
            setUsers(usersData);
            setUserOptions(usersData.map((user) => ({value: user.id, label: user.name})));
        }

        fetchUsers();
    }, []);

    const handleProjectNameChange = (event) => {
        setProjectName(event.target.value);
    };

    const handleOptionChange = (event) => {
        const {value, checked} = event.target;

        if (checked) {
            setSelectedOptions((prevSelectedOptions) => [...prevSelectedOptions, value]);
        } else {
            setSelectedOptions((prevSelectedOptions) => prevSelectedOptions.filter((option) => option !== value));
        }
    };

    function handleUserSelect(selectedOptions) {
        const selectedUserIds = selectedOptions.map((option) => option.value);
        setUsers(users.map((user) => ({...user, isSelected: selectedUserIds.includes(user.id)})));
    }

    const handleSave = async () => {
        try {
            const projectDoc = doc(db, 'projects', id);
            await updateDoc(projectDoc, {name: projectName});
            console.log('Nome do projeto salvo com sucesso!');
        } catch (error) {
            console.log('Erro ao salvar nome do projeto:', error);
        }
    };

    return (
        <>
            <div className="mb-3 rounded" style={{backgroundColor: 'gray', padding: '50px 40px'}}>
                {/* Banner */}
                <h1 className="text-white">Nome do Projeto</h1>
            </div>
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Form>
                                <Form.Group controlId="formProjectName">
                                    <Form.Label>Nome do Projeto</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={projectName}
                                        onChange={handleProjectNameChange}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formOptions" className="mt-2">
                                    <Form.Label>Selecione os Produtos</Form.Label>
                                    <div className="d-flex flex-wrap align-items-center">
                                        <Form.Check
                                            className="me-2"
                                            type="checkbox"
                                            id="checkboxForecasting"
                                            label="Forecasting"
                                            value="forecasting"
                                            checked={selectedOptions.includes('forecasting')}
                                            onChange={handleOptionChange}
                                        />
                                        <br/>
                                        <Form.Check
                                            className="me-2"
                                            type="checkbox"
                                            id="checkboxCockpit"
                                            label="Cockpit"
                                            value="cockpit"
                                            checked={selectedOptions.includes('cockpit')}
                                            onChange={handleOptionChange}
                                        />
                                        <Form.Check
                                            className="me-2"
                                            type="checkbox"
                                            id="checkboxCalendarioComercial"
                                            label="Calendário Comercial"
                                            value="calendarioComercial"
                                            checked={selectedOptions.includes('calendarioComercial')}
                                            onChange={handleOptionChange}
                                        />
                                        <Form.Check
                                            className="me-2"
                                            type="checkbox"
                                            id="checkboxPlanoAcao"
                                            label="Plano de ação"
                                            value="planoAcao"
                                            checked={selectedOptions.includes('planoAcao')}
                                            onChange={handleOptionChange}
                                        />
                                    </div>
                                </Form.Group>
                                <Button variant="primary" onClick={handleSave} block className="w-100">
                                    Salvar
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Card.Body>
                            <Form>
                                <Form.Group>
                                    <Form.Label>Selecione os usuários para o projeto</Form.Label>
                                    <Select options={userOptions} isMulti={true} onChange={handleUserSelect}/>
                                </Form.Group>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default ProjectConfig;
