import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Card, Row, Col} from 'react-bootstrap';
import Flex from 'components/common/Flex';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import CardDropdown from 'components/common/CardDropdown';
import {statsData} from 'data/dashboard/crm';
import classNames from 'classnames';
import IconItem from 'components/common/icon/IconItem';
import StatsChart from './StatsChart';
import {getColor} from 'helpers/utils';

const StatsItem = ({stat}) => {
    const {icon, color, title, amount, caret, caretColor, target, data} = stat;
    return (
        <>
            <Flex
                justifyContent="center"
                alignItems="center"
                className="mb-3 position-static bg-light px-2 rounded"
            >
                <h6 className="mb-0 flex-1">{title}</h6>
                <div>
                    <CardDropdown/>
                </div>
            </Flex>
            <Flex
            justifyContent="between"
            >
                <div>
                    <div>
                        <p className="mb-2">Meta: <strong>R$0</strong></p>
                    </div>
                    <Flex
                        alignItems="center"
                        className="mb-3 position-static bg-soft-success py-2 px-3 rounded"
                    >
                        <small className="text-success">Projeção:</small>
                        <p className="font-sans-serif mt-1 lh-1 mb-1 fs-1 ms-2 text-success"><strong>R$0</strong></p>
                    </Flex>
                </div>

                <div className="p-2">
                    <p className="font-sans-serif lh-1 mb-1 fs-5 pe-2 mb-0">{amount}%</p>
                    <small>Atingimento</small>
                </div>
            </Flex>
        </>
    );
};

const CrmStats = () => {
    const [stats] = useState(statsData);
    return (
        <Card>
            <Card.Body>
                <Row>
                    {stats.map((stat, index) => (
                        <Col
                            lg={4}
                            key={stat.id}
                            className={classNames({
                                'border-bottom border-lg-0 border-lg-end':
                                    index !== stats.length - 1,
                                'pb-3 pb-lg-0': index === 0,
                                'py-3 py-lg-0': index === 1,
                                'pt-3 pt-lg-0': index === 2,
                                'mt-4': index >= 3
                            })}
                        >
                            <StatsItem stat={stat}/>
                        </Col>
                    ))}
                </Row>
            </Card.Body>
        </Card>
    );
};

StatsItem.propTypes = {
    stat: PropTypes.shape({
        amount: PropTypes.number.isRequired,
        caret: PropTypes.string.isRequired,
        caretColor: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        data: PropTypes.array.isRequired,
        icon: PropTypes.string.isRequired,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        target: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired
    })
};

CrmStats.propTypes = {
    stats: PropTypes.arrayOf(PropTypes.shape(StatsItem.propTypes.stat))
};

export default CrmStats;
