import React, { useState } from 'react';
import Spreadsheet from "react-spreadsheet";

const BasicSpreadsheet = () => {
    const [data, setData] = useState([
        [{ value: "Vanilla" }, { value: "Chocolate" }, { value: "Chocolate2" }],
        [{ value: "Strawberry" }, { value: "Cookies" }],
    ]);

    const columnLabels = [
        "JAN",
        "FEV",
        "MAR",
        "ABR",
        "MAI",
        "JUN",
        "JUL",
        "AGO",
        "SET",
        "OUT",
        "NOV",
        "DEZ",
    ];

    const rowLabels = [
        "Row 1",
        "Row 2",
        "Row 3",
    ];

    return (
        <Spreadsheet
            data={data}
            onChange={setData}
            rowLabels={rowLabels}
            columnLabels={columnLabels}
        />
    );
};

export default BasicSpreadsheet;
