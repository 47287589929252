import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import db from '../../firebase';
import { Col, Row, Accordion, Card } from 'react-bootstrap';
import GreetingCard from '../../components/dashboards/e-commerce/greetings/GreetingCard';
import { notifications, saleItems, totalOrder } from '../../data/dashboard/ecom';
import WeeklySales from '../../components/dashboards/default/WeeklySales';
import { weeklySalesData } from '../../data/dashboard/default';
import TotalOrder from '../../components/dashboards/e-commerce/TotalOrder';
import EcomStat from '../../components/dashboards/e-commerce/EcomStat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import Greetings from '../../components/dashboards/project-management/Greetings';
import { discussionMembers, greetingItems,recentActivities} from '../../data/dashboard/projectManagement';
import TeamProgress from '../../components/dashboards/project-management/TeamProgress';
import Discussion from '../../components/dashboards/project-management/Discussion';
import RecentActivity from '../../components/dashboards/project-management/RecentActivity';
import ToDoList from '../../components/dashboards/project-management/ToDoList';

const Project = () => {
    const { projectId } = useParams();
    const navigate = useNavigate();
    const [project, setProject] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        let isMounted = true;

        const fetchProject = async () => {
            try {
                const docRef = doc(db, 'projects', projectId);
                const docSnapshot = await getDoc(docRef);
                if (isMounted) {
                    setProject(docSnapshot.data());
                    setLoading(false);
                }
            } catch (error) {
                console.log('Erro ao obter projeto:', error);
                if (isMounted) {
                    setError(true);
                    setLoading(false);
                }
            }
        };

        fetchProject();
        return () => {
            isMounted = false;
        };
    }, [projectId]);

    if (loading || !project) {
        return <p>Carregando...</p>;
    }

    if (error) {
        return <p>Ocorreu um erro ao buscar as informações do projeto.</p>;
    }

    return (
        <>
            <h1>{project.name}</h1>
            <Accordion defaultActiveKey="0">
                <Card className="bg-transparent shadow-none">
                    <Row>
                        <Col xxl={8}>
                            <Accordion.Header className="bg-white shadow rounded">
                                Menu Administrativo
                            </Accordion.Header>
                        </Col>
                        <Col xxl={4}>
                            <a href="/projeto/preferencias">
                                <nav aria-label="breadcrumb" className="bg-light rounded-3 p-3 mb-3">
                                    <FontAwesomeIcon icon={faCog} />
                                    <span className="ms-2">Preferências do Projeto</span>
                                </nav>
                            </a>
                        </Col>
                    </Row>
                    <Accordion.Body className="px-0">
                        <Row className="g-3 mb-3">
                            <Col xxl={6} lg={12}>
                                <Greetings data={greetingItems} />
                            </Col>
                            <Col xxl={3} md={6}>
                                <TeamProgress />
                            </Col>
                            <Col xxl={3} md={6}>
                                <Discussion data={discussionMembers} />
                            </Col>
                        </Row>
                        <Row className="g-3 mb-3">
                            <Col xxl={{ span: 6, order: 1 }} lg={6}>
                                <ToDoList />
                            </Col>
                            <Col xxl={{ span: 6, order: 1 }} lg={6}>
                                <RecentActivity data={recentActivities} />
                            </Col>
                        </Row>
                    </Accordion.Body>
                </Card>
            </Accordion>
            <Row className="mb-4 mt-2 ps-3 navbar-vertical-label-wrapper">
                <Col className="ps-0">
                    <hr className="mb-0 navbar-vertical-divider"></hr>
                </Col>
                <Col xs="auto" className="navbar-vertical-label navbar-vertical-label">
                    <small>Dashboard do Projeto</small>
                </Col>
                <Col className="ps-0">
                    <hr className="mb-0 navbar-vertical-divider"></hr>
                </Col>
            </Row>
            <Row className="g-3 mb-3">
                <Col xxl={6} xl={12}>
                    <Row className="g-3">
                        <Col xs={12}>
                            <GreetingCard notifications={notifications} />
                        </Col>
                    </Row>
                </Col>
                <Col xxl={6} xl={12}>
                    <Row className="mb-3">
                        <Col md={6}>
                            <WeeklySales data={weeklySalesData} />
                        </Col>
                        <Col md={6}>
                            <TotalOrder data={totalOrder} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <WeeklySales data={weeklySalesData} />
                        </Col>
                        <Col md={6}>
                            <TotalOrder data={totalOrder} />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <EcomStat data={saleItems} />
                </Col>
            </Row>
        </>
    );
};

Project.propTypes = {
    projectId: PropTypes.string,
};

export default Project;
