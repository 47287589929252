import React from "react";
import {Col, Row} from "react-bootstrap";
import GreetingCard from "../../components/dashboards/e-commerce/greetings/GreetingCard";
import { notifications, saleItems, totalOrder, totalSale} from "../../data/dashboard/ecom";
import WeeklySales from "../../components/dashboards/default/WeeklySales";
import { weeklySalesData} from "../../data/dashboard/default";
import TotalOrder from "../../components/dashboards/e-commerce/TotalOrder";
import EcomStat from "../../components/dashboards/e-commerce/EcomStat";
import {faCog} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const DashboardProject = () => {
    return (
        <>
            <Row className="g-3 mb-3">
                <Col xxl={6} xl={12}>
                    <Row className="g-3">
                        <Col xs={12}>
                            <GreetingCard notifications={notifications}/>
                        </Col>
                    </Row>
                </Col>
                <Col xxl={6} xl={12}>
                    <Col>
                        <nav aria-label="breadcrumb" className="bg-light rounded-3 p-3 mb-3">
                            <FontAwesomeIcon icon={faCog}/>
                            <span className="ms-2">Preferências do Projeto</span>
                        </nav>
                    </Col>
                    <Row className="mb-3">
                        <Col md={6} className="pe-2">
                            <WeeklySales data={weeklySalesData} />
                        </Col>
                        <Col md={6} className="ps-2">
                            <TotalOrder data={totalOrder} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} className="pe-2">
                            <WeeklySales data={weeklySalesData} />
                        </Col>
                        <Col md={6} className="ps-2">
                            <TotalOrder data={totalOrder} />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <EcomStat data={saleItems}/>
                </Col>
            </Row>
        </>
    );
};

export default DashboardProject;