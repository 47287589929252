import React from 'react';
import {Container, Row} from 'react-bootstrap';
import Spreadsheet from "../../../../components/spreadsheet";

const Accomplished = () => {
    return (
        <>
            <h1>Planejado</h1>

            <Spreadsheet/>
        </>
    );
};

export default Accomplished;
