import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Card, Col, Row } from 'react-bootstrap';
import Background from 'components/common/Background';
import bgImage from 'assets/img/icons/spot-illustrations/corner-5.png';
import connectCircle from 'assets/img/icons/connect-circle.png';

const ConnectCard = () => {
    const [connected, setConnected] = useState(false);
    const [analyticsData, setAnalyticsData] = useState(null);

    useEffect(() => {
        const clientId = process.env.REACT_APP_GA4_CLIENT_ID;
        const scopes = 'https://www.googleapis.com/auth/analytics.readonly';

        const loadGoogleAnalyticsAPI = () => {
            const script = document.createElement('script');
            script.src = 'https://accounts.google.com/gsi/client';
            script.async = true;
            script.onload = initializeGoogleAnalytics;
            document.body.appendChild(script);
        };

        const initializeGoogleAnalytics = () => {
            window.google.accounts.id.initialize({
                client_id: clientId,
                callback: (response) => {
                    if (response.credential) {
                        setConnected(true);
                        loadAnalyticsData();
                    }
                },
                context: 'use',
                scope: scopes,
            });

            window.google.accounts.id.renderButton(document.getElementById('connect-button'), {
                theme: 'outline',
                size: 'large',
                width: '240',
                height: '50',
                locale: 'pt-BR',
            });
        };

        loadGoogleAnalyticsAPI();
    }, []);

    const loadAnalyticsData = async () => {
        try {
            const data = await fetchAnalyticsData();
            setAnalyticsData(data);
        } catch (error) {
            console.log('Erro ao buscar os dados do Google Analytics:', error);
        }
    };

    const fetchAnalyticsData = async () => {
        try {
            const response = await axios.get('/api/google-analytics'); // Alteração na URL para uma rota interna
            return response.data;
        } catch (error) {
            console.log('Erro ao buscar os dados do Google Analytics:', error);
            throw error;
        }
    };

    return (
        <>
            {connected ? null : (
                <Card className="mb-3">
                    <Background
                        image={bgImage}
                        className="bg-card"
                        style={{
                            borderTopRightRadius: '0.375rem',
                            borderBottomRightRadius: '0.375rem',
                        }}
                    />
                    <Card.Body className="position-relative">
                        <Row className="g-2 align-items-sm-center">
                            <Col xs="auto">
                                <img src={connectCircle} alt="connectCircle" height={55} />
                            </Col>
                            <Col>
                                <Row className="align-items-center">
                                    <Col className="pe-xl-8">
                                        <h5 className="fs-0 mb-3 mb-sm-0 text-black">
                                            Conecte sua conta do Google Analytics para visualizar os dados em tempo real.
                                        </h5>
                                    </Col>
                                    <Col xs="auto" className="ms-auto">
                                        <div id="connect-button"></div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            )}
        </>
    );
};

export default ConnectCard;
